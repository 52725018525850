@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
    justify-content: space-between;
  }
}

.score {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rating {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: -0.5rem 0 0;
}

.button {
  align-items: center;
  display: flex !important;
  justify-content: center;
  gap: 1rem;
  width: 100% !important;
}

.hr {
  border-color: $slate-100 !important;
  margin: 0 !important;
}

.data {
  display: none;

  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.active {
  display: block;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.element {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  .value {
    color: $gray-500;
    font-size: 14px;
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    .value {
      margin-left: 0.25rem;
    }
  }
}
