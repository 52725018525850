@import "../../../styles/variables";
@import "../../../styles/mixins";

.contentDocumen{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    @include media-breakpoint-up(xl) {
        gap: 1rem;
        flex-direction: row;
      }
}
.document{
    width: 100%;
}
.documentExpeditionDate{
    min-width: 0;

    @include media-breakpoint-up(xl) {
        min-width: 260px;
      }
}
