@import "../../../../styles/variables";
@import "../../../../styles/mixins";
.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;

  @include media-breakpoint-up(md) {
    max-width: 360px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.subscription {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.plan {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.icon {
  color: $teal-500;
  font-size: 16px;
}

.info {
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.element {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &:last-child > div {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  svg {
    margin: 0;
    color: $primary;
  }
}

.element + .element {
  border-left: $eb-border;
  padding-left: 2rem;
}

.text {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.hint {
  color: $gray-500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.equal {
  color: $blue-500;
}

.increase {
  color: $teal-500;
}

.decrease {
  color: $red-500;
}
